import React, { Component } from 'react';
import './ExcelHelp.css';
import M from 'materialize-css/dist/js/materialize.min.js'

class About extends Component {
    
    state = {  }

    componentDidMount() {
    }

    render() { 
        return (
            <div className="page container">
                <section className="section" id="services">
                    <div className="row">
                        <div className="col s12 l12">
                            <h3 className="">Help</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 l4">
                            <h5 className="">Topic 1</h5>
                        </div>
                        <div className="col s12 l6 offset-l2">
                            <p>Bla bla bla bla bla bla bla bla bla bla bla  bla
                            bla bla bla bla bla bla bla bla bla bla bla bla
                            bla bla bla bla bla bla bla bla bla bla bla bla
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 l4">
                            <h5 className="">Topic 2</h5>
                        </div>
                        <div className="col s12 l6 offset-l2">
                            <p>Bla bla bla bla bla bla bla bla bla bla bla  bla
                            bla bla bla bla bla bla bla bla bla bla bla bla
                            bla bla bla bla bla bla bla bla bla bla bla bla
                            </p>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
 
export default About;