import React, { Component } from 'react';
import './Contact.css';
import {withFirebase} from './FirebaseContext'
import M from 'materialize-css/dist/js/materialize.min.js'

class ContactBase extends Component {

    componentDidMount() {
        M.Tooltip.init(this.DOMinstance1, {});
        M.Tooltip.init(this.DOMinstance2, {});
    }

    state = {};

    onSubmit = event => {
        event.preventDefault();
        document.getElementById("contact-form-result").textContent = "";

        const to = "letsforecast.com.au@gmail.com";
        const from = document.getElementById("contact-form-email").value;
        const message = document.getElementById("contact-form-message").value;
        const subject = '"Get in touch" email from: ' + from;

        if(!message) {
            document.getElementById("contact-form-result").textContent = "Write something in the message field";
            return;
        }
        if(!from) {
            document.getElementById("contact-form-result").textContent = "Please provide your email";
            return;
        }

        this.props.firebase.sendEmail(from, to, subject, message).then(() => {
            document.getElementById("contact-form").reset();
            document.getElementById("contact-form-result").textContent = "Message sent";
        }).catch(error => {
            document.getElementById("contact-form-result").textContent = "Message not sent. " + error.message;
        });
    };

    render() { 
        return (
            <div className="page container">
                <section className="section scrollspy" id="contact">
                    <div className="row">
                    <div className="col s12 l5">
                        <h5 className="">Get in touch</h5>
                        <p>Do you have a data science problem or a question? Give us a nudge.</p>
                    </div>
                    <div className="col s12 l5 offset-l2">
                        <form onSubmit={this.onSubmit} id="contact-form">
                            <div className="input-field">
                                <i className="material-icons prefix"  data-tooltip="Your Email"
                                    ref={x => {
                                        this.DOMinstance1 = x;
                                    }} 
                                >email</i>
                                <input type="email" id="contact-form-email"/>
                                {/* <label htmlFor="email">Your Email</label> */}
                            </div>
                            <div className="input-field">
                                <i className="material-icons prefix" data-tooltip="Your Message"
                                    ref={x => {
                                        this.DOMinstance2 = x;
                                    }}
                                >message</i>
                                <textarea id="contact-form-message" className="materialize-textarea" cols="20" rows="20"></textarea>
                                {/* <label htmlFor="message">Your Message</label> */}
                            </div>
                            <p className="" id="contact-form-result"></p>
                            <div className="center">
                                <button className="btn">Submit</button>
                            </div>
                        </form>
                    </div>
                    </div>
                </section>
            </div>
        );
    }
}

const Contact = withFirebase(ContactBase);

export default Contact;