import React, { Component } from 'react';
import './Home.css';

class Home extends Component {
    
    state = {  }

    render() { 
        return (
            <div className="page container">
                <section className="section" id="photos">
                <div className="row">
                        <div className="col s4 m4 l3">
                            <img src="img/man_looking.jpg" alt="Data Science" className="responsive-img"></img>
                        </div>
                        <div className="col s7 offset-s1 m7 offset-m1 l6 offset-l1">
                            <h5 className="">Data Science</h5>
                            <p>We are passionate about Data Science. We beleive that true Data Science is where the research meets practice.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s4 offset-s1 push-s7 m4 offset-m1 push-m7 l3 offset-l1 push-l8">
                            <img src="img/ruthless_woman.jpg" alt="Practice" className="responsive-img"></img>
                        </div>
                        <div className="col s6 offset-s1 pull-s5 m6 offset-m1 pull-m5 l6 offset-l1 pull-l3 right-align">
                            <h5 className="">Practice</h5>
                            <p>We are dedicated researchers and practicians and we love forecasting problems.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s4 m4 l3">
                            <img src="img/man_at_board.jpg" alt="Research" className="responsive-img"></img>
                        </div>
                        <div className="col s7 offset-s1 m7 offset-m1 l6 offset-l1">
                            <h5 className="">Research</h5>
                            <p>We beleive in unlimited possibilities of ML and AI. We eager to learn and to share our knowledge.</p>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
 
export default Home;