import React, { Component } from 'react';
import './Wallet.css';
import {withFirebase} from './FirebaseContext';
import M from 'materialize-css/dist/js/materialize.min.js'

class WalletBase extends Component {

    state = {
        balance: "...",
        currency: ""
    }

    mounted = false;
    recharge_clicked = false;

    constructor(props) {
        super(props);

        props.firebase.auth.onAuthStateChanged(user => {
            if(user) {
                this.props.firebase.firestore.collection('users').doc(user.uid)
                .onSnapshot((doc) => {
                    if(this.mounted) {
                        if(doc.exists) {
                            var userInfo = doc.data();
                            this.setState({
                                balance: Math.round(userInfo.balance*100)/100,
                                currency: userInfo.currency.toString().toUpperCase()
                            });
                        } else {
                            console.log("No such user!");
                        }
                    }
                });
            }
        });

        this.functions = props.firebase.functions;
    }

    componentDidMount() {
        this.mounted = true;
        M.Tooltip.init(this.DOMinstance1, {});
    }

    async recharge() {
        if(this.recharge_clicked) {
            console.log("Recharge already clicked!");
            return;
        }
        this.recharge_clicked = true;
        console.log("Recharge clicked!");
        try {
            var create_checkout_session = this.functions.httpsCallable('create_checkout_session');
            var result = await create_checkout_session({
                url: window.location.href
            })
            console.log(result);
            var sessionId = result.data.sessionId;
            var url = result.data.url;
            console.log(sessionId, url);
            window.open(url);
        }
        finally {
            this.recharge_clicked = false;
        }
    }

    render() { 
        return (
            <div className="page container">
                <section className="section scrollspy">
                    <div className="row">
                        <div className="col s12 l12">
                            <h5 className="">Wallet</h5>
                            <div className="row">
                                <div className="col s8 l3">
                                    <h5 className="right">Your balance:</h5>
                                </div>
                                <div className="col s4 l3 offset-l2">
                                    <h5>{this.state.balance} {this.state.currency}</h5>
                                </div>
                                    <div className="col s12 l2 offset-l2">
                                    <button className="btn"
                                    onClick={async () => await this.recharge()}
                                    data-tooltip="Your payments will be processed by Stripe. The button will open a new window."
                                    ref={x => {
                                        this.DOMinstance1 = x;
                                    }}>RECHARGE</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 l12">
                            <p className="" id="messages"></p>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const Wallet = withFirebase(WalletBase);

export default Wallet;
