import React, { Component } from 'react';
import './About.css';
import M from 'materialize-css/dist/js/materialize.min.js'

class About extends Component {
    
    state = {  }

    componentDidMount() {
        M.Tabs.init(this.DOMinstance, {});
    }

    render() { 
        return (
            <div className="page container">
                <section className="section" id="services">
                    <div className="row">
                    <div className="col s12 l4">
                        <h5 className="">What we do</h5>
                        <p>We are a consultancy as well as a startup business.</p>
                    </div>
                    <div className="col s12 l6 offset-l2">
                        <ul className="tabs"
                            ref={x => {
                                this.DOMinstance = x;
                            }}
                        >
                            <li className="tab col s6">
                                <a href="#consultancy" className="active">Consultancy</a>
                            </li>
                            <li className="tab col s6">
                                <a href="#startup" className="">Startup</a>
                            </li>
                        </ul>
                        <div id="consultancy" className="col s12">
                            {/* <p className="flow-text white-text">Consultancy</p> */}
                            <p>As a consultancy we are dedicated to provide professional Data Science service.</p>
                        </div>
                        <div id="startup" className="col s12">
                            {/* <p className="flow-text white-text">Startup</p> */}
                            <p>As a startup we are focused on implementing our web-based products.</p>
                        </div>
                    </div>
                    </div>
                </section>
            </div>
        );
    }
}
 
export default About;