// import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar.js';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/Home.js";
import About from "./components/About.js";
import Contact from "./components/Contact.js";
import Login from "./components/Login.js";
import Login2 from "./components/Login2.js";
import Signup from "./components/Signup.js";
import Wallet from "./components/Wallet.js";
import ClinicalCoder from "./components/ClinicalCoder.js";
import ExcelHelp from "./components/ExcelHelp.js";
import {withFirebase} from "./components/FirebaseContext";
import React, { Component } from 'react';


class SwitchBoardBase extends Component {
  mounted = false;

  state = {
    loggedIn: false
  }

  constructor(props) {
      super(props);
      props.firebase.auth.onAuthStateChanged(user => {
        if(this.mounted) {
          if(user) {
            console.log("App: logged in...");
            this.setState({loggedIn: true});
          }
          else {
            console.log("App: logged out...");
            this.setState({loggedIn: false});
          }
        }
      });
  }

  componentDidMount() {
    this.mounted = true;
  }

  render() {
    return (
      <div>
        {this.state.loggedIn ?
            <Switch>
              <Route path="/about" exact component={About} />
              <Route path="/contact" exact component={Contact} />
              <Route path="/login" exact component={Login} />
              <Route path="/logout" exact component={Login} />
              <Route path="/login2" exact component={Login2} />
              <Route path="/signup" exact component={Signup} />
              <Route path="/wallet" exact component={Wallet} />
              <Route path="/clinical-coder" exact component={ClinicalCoder} />
              <Route path="/excel-help" exact component={ExcelHelp} />
              <Route path="/" component={Home} />
            </Switch>
          :
            <Switch>
              <Route path="/about" exact component={About} />
              <Route path="/contact" exact component={Contact} />
              <Route path="/login" exact component={Login} />
              <Route path="/logout" exact component={Login} />
              <Route path="/login2" exact component={Login2} />
              <Route path="/signup" exact component={Signup} />
              <Route path="/wallet" exact component={Login2} />
              <Route path="/excel-help" exact component={ExcelHelp} />
              <Route path="/" component={Home} />
            </Switch>  
        }
      </div>
    );
  }
}
const SwitchBoard = withFirebase(SwitchBoardBase);

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        {/* <Switch>
          <Route path="/about" exact component={About} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/login" exact component={Login} />
          <Route path="/logout" exact component={Login} />
          <Route path="/login2" exact component={Login2} />
          <Route path="/signup" exact component={Signup} />
          <Route path="/wallet" exact component={Wallet} />
          <Route path="/" component={Home} />
        </Switch> */}
        <SwitchBoard />
      </Router>
      <footer className="page-footer">
        <div className="contsainer">
          <small>
            &copy; Copyright 2023, Let's Forecast
          </small>
        </div>
        </footer>
    </div>
  );
}

export default App;
