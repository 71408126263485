import React, { Component } from 'react';
import './ClinicalCoder.css';
import {withFirebase} from './FirebaseContext';
import M from 'materialize-css/dist/js/materialize.min.js'
import Table from './Table';
import Axios from "axios"


const { v4: uuidv4 } = require('uuid');


class ClinicalCoderBase extends Component {

    headerData = {
        'ICD-10 code': 'ICD-10',
        'ICD-10 code description': 'Description',
        'Justification': 'Explanations',
        'Citations from the medical text justifying the diagnosis': 'Citations'
    };

    state = {
        message: null,
        icd_result: null,
        code_clicked: false,
        progress: 0.0,
        task_id: null
    }

    mounted = false;

    constructor(props) {
        super(props);

        props.firebase.auth.onAuthStateChanged(user => {
            if(user) {
            }
        });

        this.functions = props.firebase.functions;
    }

    componentDidMount() {
        this.mounted = true;
        M.Tooltip.init(this.DOMinstance1, {});
        M.Tooltip.init(this.DOMinstance2, {});
        M.Tooltip.init(this.DOMinstance3, {});
    }

    async copy() {
        console.log("Copy button clicked");
        const toClipboard = this.state.icd_result.filter(rec => rec.Selected).map(rec => rec['ICD-10 code']).join(', ')
        navigator.clipboard.writeText(toClipboard);
        console.log("ICD-10 codes are sent to the clipboard:", toClipboard);
    }

    async code_to_icd() {
        if(this.state.code_clicked) {
            console.log('"Code" already clicked!');
            return;
        }

        this.state.task_id = uuidv4();
        this.setState({
            message: null,
            icd_result: null,
            task_id: this.state.task_id,
            code_clicked: true,
            progress: 0.0
        });
        console.log('"Code" clicked!');

        var unsubscribe = null;
        try {
            const text = document.getElementById("text-to-code").value;
            if(!text) {
                this.setState({
                    message: "Text/Discharge notes must be provided",
                    icd_result: null
                });
                return;
            }

            unsubscribe = this.props.firebase.firestore.collection('progress').doc(this.state.task_id)
            .onSnapshot((doc) => {
                if(doc.exists) {
                    var progressInfo = doc.data();
                    this.setState({
                        progress: progressInfo.progress
                    });
                }
                // else {
                //     console.log("No such task_id");
                // }
            });

            // var code_to_icd_server = this.functions.httpsCallable('code_to_icd');
            // var result = await code_to_icd_server({
            //     text: text,
            //     task_id: this.state.task_id
            // })

            const url = 'https://ask-openai-gos5hiirta-uc.a.run.app';
            const idToken = await this.props.firebase.auth.currentUser.getIdToken(true);
            const response = await Axios.post(
              url,
              {
                text: text,
                task_id: this.state.task_id,
                id_token: idToken
                // uid: uid,
                // email: email
              }//,
            //   {
            //     headers: {
            //       Authorization: `Bearer ${idToken}`
            //     }
            //   }
            )

            console.log('response:', response);


            console.log('response.data:', response.data);

            if('error' in response.data) {
                this.setState({
                    message: response.data['error'],
                    icd_result: null
                });
            }
            else {
                const result = response.data.map(code => ({...code, Selected: code["Confidence level"] > 1}));
                this.setState({
                    message: null,
                    icd_result: result
                });
            }
        }
        finally {
            if(unsubscribe) {
                unsubscribe();
            }
            this.setState({code_clicked: false});
        }
    }

    render() { 
        return (
            <div className="page container">
                <section className="section2 scrollspy">
                    <div className="row">
                        <div className="col s12 l12">
                            <h5 className="center">Clinical coding</h5>
                            <div className="row">
                                <div className="input-field">
                                    <i className="material-icons prefix" data-tooltip="Your text / Discharge notes"
                                        ref={x => {
                                            this.DOMinstance2 = x;
                                        }}
                                    >message</i>
                                    <textarea id="text-to-code" className="materialize-textarea" cols="20" rows="20"></textarea>
                                </div>
                            </div>
                            {/* {!this.state.code_clicked && */}
                            <div style={{display: !this.state.code_clicked ? "block" : "none"}} className="row">
                                {/* <div className="col s12 l2 offset-l2"> */}
                                    <button className="btn"
                                    onClick={async () => await this.code_to_icd()}
                                    data-tooltip="AI will work on your text to assign relevant ICD codes"
                                    ref={x => {
                                        this.DOMinstance1 = x;
                                    }}>Code</button>
                                {/* </div> */}
                            </div>
                            {/* } */}
                            {this.state.code_clicked &&
                                <div>
                                    <div className="row" id="space"/>
                                    <div className = "progress">
                                        <div className = "determinate" style = {{width: (this.state.progress*100)+'%'}}></div>  
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    { this.state.message &&
                        <div className="row">
                            <div className="col s12 l12">
                                <p className="" id="messages">{this.state.message}</p>
                            </div>
                        </div>
                    }
                    { this.state.icd_result &&
                        <div className="row">
                            <div className="col s12 l12">
                                <Table
                                    headers={Object.keys(this.headerData).map(key => this.headerData[key])}
                                    keys={Object.keys(this.headerData)}
                                    body={this.state.icd_result}
                                    callback={(index) => {
                                        this.state.icd_result[index]['Selected'] = !this.state.icd_result[index]['Selected']
                                        this.setState({icd_result: this.state.icd_result})
                                    }}
                                /> 
                            </div>
                        </div>
                    }
                    <div style={{display: this.state.icd_result ? "block" : "none"}} className="row">
                        {/* <div className="col s12 l2 offset-l2"> */}
                            <button className="btn"
                            onClick={async () => await this.copy()}
                            data-tooltip="Click to copy selected codes to the clipboard"
                            ref={x => {
                                this.DOMinstance3 = x;
                            }}>Copy</button>
                        {/* </div> */}
                    </div>
                </section>
            </div>
        );
    }
}

const ClinicalCoder = withFirebase(ClinicalCoderBase);

export default ClinicalCoder;
