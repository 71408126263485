import React, { Component } from 'react';
import M from 'materialize-css/dist/js/materialize.min.js';
import { Link } from 'react-router-dom';
import {withFirebase} from './FirebaseContext'
import './Navbar.css';

class NavbarBase extends Component {

    mounted = false;

    constructor(props) {
        super(props);
        this.setState({user: props.firebase.auth.currentUser});

        props.firebase.auth.onAuthStateChanged(user => {
            if(this.mounted) {
                this.setState({user: user});
            }
        });
    }

    state = {
        user: null
        // seconds: 0
    }

    componentDidMount() {
        M.Sidenav.init(this.DOMinstance, {
            menuWidth: 300,
            closeOnClick: true,
            edge: 'right'
        });
        
        M.Dropdown.init(document.getElementById("dropdown-trigger"), {
            hover: true,
            coverTrigger: false
        });

        this.mounted = true;

        // this.timer = setInterval(() => {
        //     this.setState({ seconds: this.state.seconds === 0 ? 1 : 0 });
        //     console.log(this.state.seconds);
        //     let e = document.getElementById("header");
        //     if(this.state.seconds === 0) {
        //         e.setAttribute("style", "background: url(./img/Banner4.jpg); transition: background 5s linear; background-size: cover; min-height: 400px; background-position: center;");
        //     }
        //     else {
        //         e.setAttribute("style", "background: url(./img/Banner2.jpg); transition: background 5s linear; background-size: cover; min-height: 400px; background-position: center;");
        //     }
        // }, 16000);
    }

    componentWillUnmount() {
        this.mounted = false;
    //     clearInterval(this.timer);
    }

    render() {
        return (
            <header id="header">
                <div>
                    <ul id="home" className="dropdown-content">
                        <li><Link to='/about'>About</Link></li>
                        <li className="divider"></li>
                        <li><Link to='/contact'>Contact</Link></li>
                    </ul>
                    <nav className="nav-wrapper transparent">
                        <div className="container">
                            <Link to='/' className="brand-logo logo-l left hide-on-med-and-down">
                                <img src="img/Let's Forecast Logo-03.png" alt="Let's Forecast"/>
                            </Link>
                            <Link to='/' className="brand-logo logo-m left hide-on-large-only hide-on-small-only">
                                <img src="img/Let's Forecast Logo-03.png" alt="Let's Forecast"/>
                            </Link>
                            <Link to='/' className="brand-logo logo-s left hide-on-med-and-up">
                                <img src="img/Let's Forecast Logo-05.png" alt="Let's Forecast"/>
                            </Link>
                            <a href="/" className="sidenav-trigger right" data-target="mobile-links">
                                <i className="material-icons">menu</i>
                            </a>
                            <ul className="right hide-on-med-and-down nav-links">
                                <li>
                                    <Link to='/'  id='dropdown-trigger' className="dropdown-trigger" data-target="home">Home</Link>
                                </li>
                                {/* <li>
                                    <Link to='/about'>About</Link>
                                </li>
                                <li>
                                    <Link to='/contact'>Contact</Link>
                                </li> */}
                                {this.state.user &&
                                    <li>
                                        <Link to='/wallet'>Wallet</Link>
                                    </li>
                                }
                                {this.state.user &&
                                    <li>
                                        <Link to='/clinical-coder'>Clinical coder</Link>
                                    </li>
                                }
                                {this.state.user ?
                                    <li>
                                        <Link to='/logout'>Logout</Link>
                                    </li>
                                :
                                    <li>
                                        <Link to='/login'>Login</Link>
                                    </li>
                                }
                            </ul>
                        </div>
                    </nav>

                    <ul className="sidenav" id="mobile-links"
                        ref={x => {
                            this.DOMinstance = x;
                        }}
                    >
                        <div className="sidenav-space">
                        </div>
                        <li className="sidenav-close">
                            <Link to='/'>Home</Link>
                        </li>
                        <li className="sidenav-close">
                            <Link to='/about'>About</Link>
                        </li>
                        <li className="sidenav-close">
                            <Link to='/contact'>Contact</Link>
                        </li>
                        {this.state.user ?
                            <div>
                                <li className="sidenav-close">
                                    <Link to='/wallet'>Wallet</Link>
                                </li>                    
                                <li className="sidenav-close">
                                    <Link to='/clinical-coder'>Clinical coder</Link>
                                </li>                    
                                <li className="sidenav-close">
                                    <Link to='/login'>Logout</Link>
                                </li>
                            </div>
                        :
                            <li className="sidenav-close">
                                <Link to='/login'>Login</Link>
                            </li>
                        }
                    </ul>
                </div>
            </header>
        );
    }
}
 
const Navbar = withFirebase(NavbarBase);
export default Navbar;
