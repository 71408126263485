import Checkbox from "@material-ui/core/Checkbox";


export default function Table({headers, keys, body, callback}) {
    return (
        <table>
            <thead>
                <tr>
                    <th>Select</th>
                    {headers.map(heading => {
                        return <th key={heading}>{heading}</th>
                    })}
                </tr>
            </thead>
            <tbody>
                {body.map((row, index1) => {
                    return <tr key={index1}>
                        <td>
                            <Checkbox
                                key={'__'+index1}
                                checked={row['Selected']}
                                onClick={async () => {callback(index1)}}
                            />
                        </td>
                        {keys.map((key, index2) => {
                            return <td key={'_'+index1+'_'+index2}>{row[key]}</td>
                        })}
                    </tr>;
                })}
            </tbody>
        </table>
    );
}
