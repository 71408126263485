import app from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';


const config = {
    apiKey: "AIzaSyCWJHBrrns_iQTSUsc2PztDcZKRPv--66A",
    authDomain: "letsforecast.firebaseapp.com",
    databaseURL: "https://letsforecast.firebaseio.com",
    projectId: "letsforecast",
    storageBucket: "letsforecast.appspot.com",
    messagingSenderId: "1715149293",
    appId: "1:1715149293:web:9d9bbbd4de331200f4afe4",
    measurementId: "G-K7J9PMVY4P"
};

class Firebase {

    auth;
    firestore;
    storage;
    functions;

    constructor() {
        app.initializeApp(config);
        this.auth = app.auth();
        this.firestore = app.firestore();
        this.storage = app.storage();
        this.functions = app.functions();
    }

    sendEmail(from, to, subject, message) {
        const record = {
            to: to,
            cc: from,
            message: {
                subject: subject,
                html: message,
            },
        };
        return this.firestore.collection('mail').add(record);
    }

    login(name, password) {
        return this.auth.signInWithEmailAndPassword(name, password);
    }

    logout() {
        this.auth.signOut();
    }
}

export default Firebase;
