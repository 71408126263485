import React, { Component } from 'react';
import './Signup.css'
import { Link } from 'react-router-dom';
import {withFirebase} from './FirebaseContext'
import M from 'materialize-css/dist/js/materialize.min.js'

class SignupBase extends Component {

    mounted = false;

    constructor(props) {
        super(props);
        this.setState({user: props.firebase.auth.currentUser});

        props.firebase.auth.onAuthStateChanged(user => {
            if(this.mounted) {
                this.setState({user: user});
            }
        });
    }

    componentDidMount() {
        M.Tooltip.init(this.DOMinstance1, {});
        M.Tooltip.init(this.DOMinstance2, {});
        M.Tooltip.init(this.DOMinstance3, {});
        this.mounted = true;
    }

    state = {
        user: null
    }

    onSignup = event => {
        event.preventDefault();
        document.getElementById("signup-form-result").textContent = "";

        const name = document.getElementById("signup-form-email").value;
        const password = document.getElementById("signup-form-password").value;
        const password2 = document.getElementById("signup-form-password-confirmation").value;

        if(!name) {
            document.getElementById("signup-form-result").textContent = "Please provide your email";
            return;
        }
        if(!password) {
            document.getElementById("signup-form-result").textContent = "Please choose your password";
            return;
        }
        if(!password2) {
            document.getElementById("signup-form-result").textContent = "Please confirm your password";
            return;
        }
        if(password !== password2) {
            document.getElementById("signup-form-result").textContent = "The password and the confirmation mush match";
            return;
        }

        this.props.firebase.auth.createUserWithEmailAndPassword(name, password).then((userCredential) => {
            document.getElementById("signup-form").reset();
            document.getElementById("signup-form-result").textContent = "You are signed up and logged in";
        }).catch(error => {
            document.getElementById("signup-form-result").textContent = "You are NOT signed up. " + error.message;
        });
    };

    onLogout = event => {
        event.preventDefault();
        this.props.firebase.logout();
    };

    render() {
        if(this.state.user) {
                return (
                <div className="page container signup">
                    <section className="section scrollspy" id="contact">
                        <div className="row">
                        <div className="col s12 l5">
                            <h5 className="">You are signed up and logged in</h5>
                            <p>You might want to top up your <Link to='/wallet'>Wallet</Link> to start forecasting. Otherwise logout.</p>
                        </div>
                        <div className="col s12 l5 offset-l2">
                            <form onSubmit={this.onLogout} id="logout-form">
                                <p className="" id="logout-form-result"></p>
                                <div className="center">
                                    <button className="btn">LOGOUT</button>
                                </div>
                            </form>
                        </div>
                        </div>
                    </section>
                </div>
            );
        }
        else {
            return (
                <div className="page container signup">
                    <section className="section scrollspy" id="contact">
                        <div className="row">
                        <div className="col s12 l5">
                            <h5 className="">Sign up</h5>
                            <p>Use your email and choose a password to sign up.</p>
                        </div>
                        <div className="col s12 l5 offset-l2">
                            <form onSubmit={this.onSignup} id="signup-form">
                                <div className="input-field">
                                    <i className="material-icons prefix"  data-tooltip="Your Email"
                                        ref={x => {
                                            this.DOMinstance1 = x;
                                        }}
                                    >email</i>
                                    <input type="email" id="signup-form-email"/>
                                </div>
                                <div className="input-field">
                                    <i className="material-icons prefix" data-tooltip="Choose Your Password"
                                        ref={x => {
                                            this.DOMinstance2 = x;
                                        }}
                                    >password</i>
                                    <input type="password" id="signup-form-password"/>
                                </div>
                                <div className="input-field">
                                    <i className="material-icons prefix" data-tooltip="Confirm Your Password"
                                        ref={x => {
                                            this.DOMinstance3 = x;
                                        }}
                                    >password</i>
                                    <input type="password" id="signup-form-password-confirmation"/>
                                </div>
                                <p className="" id="signup-form-result"></p>
                                <div className="center">
                                    <button className="btn">SIGN UP</button>
                                </div>
                            </form>
                        </div>
                        </div>
                    </section>
                </div>
            );
        }
    }
}
 
const Signup = withFirebase(SignupBase);
export default Signup;
